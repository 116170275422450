<template>
  <div class="box">
    <h1 class="title is-3">Room 追加</h1>
    <div class="field">
      <label class="label">部屋名</label>
      <div class="control has-icons-left">
        <input
          v-model="room.name"
          type="text"
          class="input"
          placeholder="部屋名"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-pen"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <label class="label">公開範囲</label>
      <div class="control">
        <div class="select">
          <select v-model="room.scope">
            <option :value="null">選択してください</option>
            <option
              v-for="(label, scope) in Scopes"
              :key="scope"
              :value="scope"
            >
              {{ label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">招待する</label>
    </div>
    <div class="field is-grouped is-grouped-centered">
      <div class="control">
        <button @click="onCancel" class="button is-link is-light">
          キャンセル
        </button>
      </div>
      <div class="control">
        <button @click="onSubmit" class="button is-primary">登録</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/app'
import { useRouter } from 'vue-router'
import { Room, Scope } from '@/types/wishlist'
import { Scopes } from '@/constants/wishlistConstants'
import { roomConverter } from '@/converter/wishlistConverter'
import { useStore } from '@/store/index'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    // const scopes = Scopes
    const db = firebase.firestore()
    // const room = reactive({
    //   name: null as string | null,
    // })
    let room: Room = reactive({
      name: '',
      amount: { bought: 0, wish: 0 },
      roles: {},
      // roles: [],
      scope: 'private' as Scope,
      wishes: [],
    })

    // const showRoom = (id: string) => {
    //   router.push({ name: 'RoomShow', params: { id: id } })
    // }
    const showWishList = (id: string) => {
      router.push({ name: 'WishListShow', params: { id: id } })
    }

    // const getUserByEmail = async (email: string) => {

    //     .getUserByEmail(email)
    //     .then(function (userRecord) {
    //       // See the UserRecord reference doc for the contents of userRecord.
    //       console.log('Successfully fetched user data:', userRecord.toJSON())
    //     })
    //     .catch(function (error) {
    //       console.log('Error fetching user data:', error)
    //     })
    // }

    const onCancel = () => router.push({ name: 'Rooms' })

    const onSubmit = () => {
      if (!store.state.user) {
        return
      }

      const newRoomRef = db
        .collection('rooms')
        .doc()
        .withConverter(roomConverter)

      newRoomRef
        .set({
          name: room.name,
          amount: room.amount,
          roles: { [store.state.user.uid]: 'owner' },
          // roles: [{ user: store.state.user.email, role: 'owner' }],
          scope: room.scope,
          wishes: [],
        })
        .then(() => {
          showWishList(newRoomRef.id)
          console.log('firebase room add success!')
        })
        .catch((error) => {
          alert(error)
          console.log(error)
        })
      /*
        db.collection('rooms')
          // docにパラメータを渡さなければ、idは自動生成
          .doc()
          .withConverter(roomConverter)
          .set({
            name: room.name,
            roles: { [store.state.user.uid]: 'owner' },
            scope: room.scope,
            wishes: [],
          })
          .then(() => {
            console.log('firebase room add success!')
          })
          .catch((error) => {
            alert(error)
            console.log(error)
          })
        // console.log('regis', room)
        */
    }
    console.log(store.state, store.state.user?.uid)
    return {
      room,
      onSubmit,
      onCancel,
      Scopes,
    }
  },
})
</script>
