
import { defineComponent, reactive } from 'vue'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/app'
import { useRouter } from 'vue-router'
import { Room, Scope } from '@/types/wishlist'
import { Scopes } from '@/constants/wishlistConstants'
import { roomConverter } from '@/converter/wishlistConverter'
import { useStore } from '@/store/index'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    // const scopes = Scopes
    const db = firebase.firestore()
    // const room = reactive({
    //   name: null as string | null,
    // })
    let room: Room = reactive({
      name: '',
      amount: { bought: 0, wish: 0 },
      roles: {},
      // roles: [],
      scope: 'private' as Scope,
      wishes: [],
    })

    // const showRoom = (id: string) => {
    //   router.push({ name: 'RoomShow', params: { id: id } })
    // }
    const showWishList = (id: string) => {
      router.push({ name: 'WishListShow', params: { id: id } })
    }

    // const getUserByEmail = async (email: string) => {

    //     .getUserByEmail(email)
    //     .then(function (userRecord) {
    //       // See the UserRecord reference doc for the contents of userRecord.
    //       console.log('Successfully fetched user data:', userRecord.toJSON())
    //     })
    //     .catch(function (error) {
    //       console.log('Error fetching user data:', error)
    //     })
    // }

    const onCancel = () => router.push({ name: 'Rooms' })

    const onSubmit = () => {
      if (!store.state.user) {
        return
      }

      const newRoomRef = db
        .collection('rooms')
        .doc()
        .withConverter(roomConverter)

      newRoomRef
        .set({
          name: room.name,
          amount: room.amount,
          roles: { [store.state.user.uid]: 'owner' },
          // roles: [{ user: store.state.user.email, role: 'owner' }],
          scope: room.scope,
          wishes: [],
        })
        .then(() => {
          showWishList(newRoomRef.id)
          console.log('firebase room add success!')
        })
        .catch((error) => {
          alert(error)
          console.log(error)
        })
      /*
        db.collection('rooms')
          // docにパラメータを渡さなければ、idは自動生成
          .doc()
          .withConverter(roomConverter)
          .set({
            name: room.name,
            roles: { [store.state.user.uid]: 'owner' },
            scope: room.scope,
            wishes: [],
          })
          .then(() => {
            console.log('firebase room add success!')
          })
          .catch((error) => {
            alert(error)
            console.log(error)
          })
        // console.log('regis', room)
        */
    }
    console.log(store.state, store.state.user?.uid)
    return {
      room,
      onSubmit,
      onCancel,
      Scopes,
    }
  },
})
