import { Scope, WishState } from '@/types/wishlist'

type DisplayText = string

const Scopes: Readonly<Record<Scope, DisplayText>> = {
  private: '非公開',
  invited: '招待した人',
  public: '公開',
}

const WishStates: Readonly<Record<WishState, DisplayText>> = {
  wish: '',
  bought: 'Bought',
  deleted: 'Deleted',
}

export { Scopes, WishStates }
